.row {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.5fr 4fr;
      grid-template-columns: 1.5fr 4fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  grid-gap: 1rem;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.row .aside {
  background: #e7e6e6;
  border: 1px solid #e7e6e6;
  border-radius: 5px;
  padding: 12px;
}

.row .aside .asideHeader .asideHeaderTitle {
  font-size: 15px;
  font-weight: normal;
  color: #505050;
  padding-bottom: 10px;
  cursor: pointer;
}

.row .aside .asideNavigation .asideNavLinksContainer {
  height: 20px;
  overflow: hidden;
  -webkit-transition: 0.3s height;
  transition: 0.3s height;
}

.row .aside .asideNavigation .asideNavLinksContainer .asideNavLinks .asideNavItem .asideNavLink {
  display: block;
  margin-bottom: 15px;
}

.row .aside .asideNavigation .asideNavLinksContainer .asideNavLinks .asideNavItem .asideNavLink .asideNavIcon {
  margin-right: 12px;
  font-size: 17px;
  color: #6b6b6b;
}

.row .aside .asideNavigation .asideNavLinksContainer .asideNavLinks .asideNavItem .asideNavLink .asideNavLinkText {
  font-size: 14px;
  color: #7c7c7c;
}

.row .aside .asideNavigation .asideNavLinksToggler {
  margin-top: 15px;
}

.row .aside .asideNavigation .asideNavLinksToggler .toggleBtn {
  background: none;
  border: 0;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.row .aside .asideNavigation .asideNavLinksToggler .toggleBtn .asideTogglerIcon {
  font-size: 17px;
  color: #6b6b6b;
}

.row .aside .asideNavigation .asideNavLinksToggler .toggleBtn .asideTogglerText {
  color: #7c7c7c;
  font-size: 14px;
  margin-right: 10px;
}

.row .aside .aside-footer {
  text-align: center;
  color: #1038ec;
  font-weight: 600;
  padding: 8px 0;
  margin-top: 10px;
  border-top: 2px solid #fff;
  font-size: 14px;
  border-bottom: 2px solid #fff;
}

.row .main {
  background: #e7e6e6;
  border: 1px solid #e7e6e6;
  border-radius: 2px;
  padding: 10px;
}

.row .main .followSection .followSectionHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 0;
}

.row .main .followSection .followSectionHeader .followHeading {
  color: #505050;
  font-size: 16px;
  font-weight: normal;
}

.row .main .followSection .followSectionHeader .followSectionBtn {
  color: #505050;
  border: 0;
  cursor: pointer;
}

.row .main .followSection .followSectionRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
}

.row .main .followSection .followSectionRow .col {
  -ms-flex-preferred-size: 200px;
      flex-basis: 200px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.row .main .followSection .followSectionRow .col .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 2px solid #e7e6e6;
  padding: 10px 10px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}

.row .main .followSection .followSectionRow .col .card .removeUserAction {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.row .main .followSection .followSectionRow .col .card .removeUserAction .removeIcon {
  color: #fff !important;
}

.row .main .followSection .followSectionRow .col .card:hover {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.row .main .followSection .followSectionRow .col .card .banner {
  position: relative;
  width: 100%;
  height: 60px;
}

.row .main .followSection .followSectionRow .col .card .banner .bannerImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.row .main .followSection .followSectionRow .col .card .userAvatar {
  width: 67px;
  height: 67px;
  margin-top: -40px;
  margin-left: 12px;
  z-index: 1;
}

.row .main .followSection .followSectionRow .col .card .userAvatar .userAvatarImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}

.row .main .followSection .followSectionRow .col .card .userName {
  font-size: 15px;
  color: #292929;
  margin-top: 5px;
  font-weight: 500;
}

.row .main .followSection .followSectionRow .col .card .userName .fakeLogo {
  padding: 1px 3px;
  color: #ffff;
  background: #0a66c2;
  font-weight: 600;
  border-radius: 4px;
}

.row .main .followSection .followSectionRow .col .card .jobTitle {
  color: #7c7c7c;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.row .main .followSection .followSectionRow .col .card .tags {
  font-size: 14px;
  color: #7c7c7c;
  margin: 20px 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.row .main .followSection .followSectionRow .col .card .userAction .followBtn {
  background: none;
  color: #0a66c2;
  border: 1px solid #0a66c2;
  border-radius: 20px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
}

@media (max-width: 768px) {
  .row {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .row .main .followSection .followSectionRow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
