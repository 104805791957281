.footer {
  .footerWrapper {
    padding: 0 15px;

    .footerNavLinks {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      .footerNavLink {
        font-size: 12px;
        margin: 12px;
        color: #a3a2a2;
        white-space: nowrap;
      }
    }
    .siteCredit {
      text-align: center;
      white-space: nowrap;
      color: #404040;
      font-size: 12px;
      margin-top: 10px;
      .fakeLogoText {
        color: #0a66c2;
        .fakeLogo {
          padding: 1px 3px;
          color: #ffff;
          background: #0a66c2;
          border-radius: 4px;
        }
      }
    }
  }
}
