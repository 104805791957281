* {
  padding: 0;
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Inconsolata:wght@200;300;400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,500&display=swap");

// .container{
//     margin: 0 auto;
// }
.container {
  padding: 0 8%;
  margin-top: 25px;
}
:root {
  --color-brand: #0a66c2;
}

body {
  font-family: system-ui, "Roboto";
  border-style: border-box;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  overflow-x: hidden !important;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  position: relative;
  z-index: 0 !important;
  scroll-behavior: smooth;
  line-height: 1.5;
  background-color: rgb(240, 240, 240);
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.Pagecontents{
  display: none;
}