.mainFeed {
  padding: 0 0 30px;
}

.mainFeed .mainFeedRow {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr;
      grid-template-columns: 1fr 4fr;
  gap: 1rem;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #ffffff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-bottom: 10px;
  overflow: hidden;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .userBanner {
  position: relative;
  width: 100%;
  height: 60px;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .userBanner .bannerImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .userProfile {
  width: 67px;
  height: 67px;
  margin-top: -40px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .userProfile .userProfileImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardBody {
  padding: 20px 10px;
  text-align: center;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardBody .userName {
  color: #505050;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardBody .updatePhotoBtn {
  color: #0a66c2;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardFooter .userListItems .networkRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardFooter .userListItems .userListItem {
  display: block;
  cursor: pointer;
  padding: 13px 13px;
  border-top: 1px solid #d1d0d0;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardFooter .userListItems .userListItem .userListItemTitle {
  color: #a09e9e;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardFooter .userListItems .userListItem .userListItemText {
  color: #3b3b3b;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardFooter .userListItems .userListItem:nth-child(1) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardFooter .userListItems .networkIcon {
  color: #292929;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardFooter .userListItems .promotionIcon {
  color: #e77407;
  margin-right: 10px;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .card .cardFooter .userListItems .fa-bookmark {
  margin-right: 10px;
  color: #505050;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .sidebarFooter {
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  padding: 10px 15px;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .sidebarFooter .sidebarNavLinks .sidebarNavItem .sidebarNavLink {
  font-size: 14px;
  display: block;
  color: #0a66c2;
  margin: 10px 0;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .sidebarFooter .sidebarNavLinks .sidebarNavItem .sidebarNavLink.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .sidebarFooter .sidebarNavLinks .sidebarNavItem .sidebarNavLink.flex .fa-plus {
  color: #505050;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .sidebarFooter .sidebarFooterActions {
  text-align: center;
}

.mainFeed .mainFeedRow .mainFeedLeftCol .leftBar .sidebarFooter .sidebarFooterActions .discoverMoreBtn {
  color: #505050;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  cursor: pointer;
}

.mainFeed .mainFeedRow .mainFeedCenter {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2.8fr 1.2fr;
      grid-template-columns: 2.8fr 1.2fr;
  gap: 1rem;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar {
  background: #ffffff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 20px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .topBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .topBar .userAvatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .topBar .userAvatar .userImg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .topBar .feedInput {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .topBar .feedInput .feedInputBtn {
  background: none;
  width: 100%;
  height: 50px;
  border-radius: 30px;
  padding: 0 20px;
  border: 1px solid #a09e9e;
  outline: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #a09e9e;
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .topBar .feedInput .feedInputBtn:hover {
  background: #dddddd;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .bottomBar {
  padding-top: 10px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .bottomBar .toolsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .bottomBar .toolsWrapper .tool button {
  border: none;
  outline: none;
  padding: 8px 15px;
  background: none;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .bottomBar .toolsWrapper .tool button:hover {
  background: #dddddd;
  cursor: pointer;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .bottomBar .toolsWrapper .tool button .btnIcon.image {
  color: #208af3;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .bottomBar .toolsWrapper .tool button .btnIcon.video {
  color: #3ff867;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .bottomBar .toolsWrapper .tool button .btnIcon.event {
  color: #f5b351;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .bottomBar .toolsWrapper .tool button .btnIcon.article {
  color: #f86377;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar .toolsBarWrapper .bottomBar .toolsWrapper .tool button .btnText {
  color: #a09e9e;
  margin-left: 10px;
  white-space: nowrap;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard {
  background: #ffffff;
  -webkit-box-shadow: 0 3px 8px rgba(78, 78, 78, 0.1);
          box-shadow: 0 3px 8px rgba(78, 78, 78, 0.1);
  border-radius: 10px;
  padding: 10px 0;
  margin-top: 20px;
  border: 1px solid #dadada;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardHeader .imageContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardHeader .imageContainer .userAvatar {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardHeader .feedInfo {
  -ms-flex-preferred-size: 200px;
      flex-basis: 200px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardHeader .feedInfo .feedCreator {
  color: #292929;
  font-weight: 600;
  font-size: 15px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardHeader .feedInfo .feedCreator .followStatus {
  font-weight: normal;
  font-size: 13px;
  color: #505050;
  list-style-type: disc;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardHeader .feedInfo .userBio {
  color: #505050;
  font-size: 11px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardHeader .feedHeaderAction {
  color: #505050;
  cursor: pointer;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardBody {
  padding: 10px 0;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardBody .feedBody {
  margin-bottom: 13px;
  padding: 0 10px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardBody .feedBody .feedDescription {
  font-size: 14px;
  color: #424242;
  overflow: hidden;
  display: -webkit-box;
  line-height: 3;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardBody .feedImageContainer {
  width: 100%;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardBody .feedImageContainer .feedImg {
  width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardFooter {
  padding: 0 10px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardFooter .feedToolsBar .toolsWrapper {
  border-top: 1px solid #b9b8b8;
  padding: 5px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardFooter .feedToolsBar .toolsWrapper .tool button {
  border: none;
  outline: none;
  padding: 8px 15px;
  background: none;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardFooter .feedToolsBar .toolsWrapper .tool button:hover {
  background: #e6e6e6;
  cursor: pointer;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardFooter .feedToolsBar .toolsWrapper .tool button .btnIcon {
  color: #505050;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .newsCard {
  background: #ffffff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 10px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .newsCard .newsCardHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .newsCard .newsCardHeader .headerTitle {
  font-size: 14px;
  color: #505050;
  font-weight: 600;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .newsCard .newsCardBody {
  height: 231px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .newsCard .newsCardBody .newsList .newsListItem .newsHeader a {
  font-size: 14px;
  font-weight: 600;
  color: #505050;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .newsCard .newsCardBody .newsList .newsListItem .newsInfo {
  font-size: 12px;
  color: #a09e9e;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .newsCard .newsCardFooter {
  margin-top: 10px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .newsCard .newsCardFooter .shoreMoreBtn {
  border: none;
  background: none;
  outline: none;
  color: #505050;
  cursor: pointer;
  font-size: 15px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .newsCard .newsCardFooter .shoreMoreBtn i {
  margin-left: 5px;
  line-height: 5px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .promotionsCard {
  background: #ffffff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 10px;
  margin: 20px 0;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .promotionsCard .promotionsCardHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .promotionsCard .promotionsCardHeader .headerTitle {
  font-size: 14px;
  color: #505050;
  font-weight: 600;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .promotionsCard .promotionLists .promotionListItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 10px;
  gap: 5px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .promotionsCard .promotionLists .promotionListItem .promotionImgContainer {
  width: 100px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .promotionsCard .promotionLists .promotionListItem .promotionImgContainer .promotionImg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .promotionsCard .promotionLists .promotionListItem .promotionInfo .promotionTitle {
  font-size: 14px;
  font-weight: 600;
  color: #505050;
  margin-bottom: 2px;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .promotionsCard .promotionLists .promotionListItem .promotionInfo .promotionText {
  font-size: 12px;
  color: #a09e9e;
}

.mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight .mainFeedRightCol .rightBar .promotionsCard .promotionLists .promotionListItem .externalLink .fa-chevron-right {
  color: #292929;
  font-weight: 700;
}

@media (max-width: 990px) {
  .mainFeed .mainFeedRow .mainFeedCenter {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .mainFeed {
    padding: 0 10px 10px;
  }
  .mainFeed .mainFeedRow {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .mainFeed .mainFeedRow .mainFeedLeftCol {
    display: none;
  }
  .mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .toolsBar {
    display: none;
  }
  .mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterLeft .feedCard .feedCardFooter .feedToolsBar .toolsWrapper .tool:nth-child(4) {
    display: none;
  }
  .mainFeed .mainFeedRow .mainFeedCenter .mainFeedCenterRight {
    display: none;
  }
}
